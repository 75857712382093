<template>
  <div class="slide-selector video-selector is-flex is-flex-direction-column">
    <!-- Host control toggle -->
    <div class="is-flex is-justify-content-space-between px-5 py-3">
      <div v-if="$parent.hostControl && !isHost">
        {{ $t('exp_videoSelector.selectedByHost') }}
      </div>
      <div v-else>{{ $t('exp_videoSelector.chooseCard') }}</div>
      <div v-if="isHost" class="is-flex">
        <b-switch size="is-small" class="has-text-weight-bold" v-model="$parent.hostControl"
          @click.native='$parent.ToggleHostControl()'>
          <d-icon icon="FaUsers" size="is-small" :class="{ 'has-text-primary': $parent.hostControl }" />
        </b-switch>
      </div>
    </div>
    <!-- vue glide -->
    <div class="slide-wrapper">
      <div class="prev-slide" @click="SwipeSlide('prev')"></div>
      <div class="next-slide" @click="SwipeSlide('next')"></div>
      <vue-glide v-if="videos.length" v-model="activeSlide" :rewind="false" :perView="1" :peek="20" :gap="5"
        :class="{ 'locked': $parent.hostControl && !isHost }" :key="sliderId" :swipeThreshold="EnableSwipe"
        @glide:swipe-end="SwipeEnded()">
        <vue-glide-slide v-for="(video, index) in videos" :key="video.id" class="pb-3">
          <div class="card is-flex is-flex-direction-column border-bottom-radius">

            <div class="card-inner">

              <div class="card-image border-color-primary" :class="{ 'playing': playing.id == video.id }"
                @click="ChangeVideo(video.id)">
                <figure class="image is-2by1" v-if="video.image">
                  <img :src="video.image.sizes.high.url" />
                </figure>
                <img src="/assets/videoselector/play-circle.png" class="circle-play" />
              </div>
              <b-field class="is-sticky m-0">
                <div class="control" style="flex: 1;">
                  <div v-if="playing.id == video.id"
                    class="border-bottom-radius p-2 has-background-primary has-text-centered has-text-white-bis has-text-weight-bold">
                    {{ $t('exp_videoSelector.playing') }}
                  </div>
                  <div v-else class="border-bottom-radius p-2 has-background-silver has-text-centered"
                    @click="ChangeVideo(video.id)">
                    {{ $t('exp_videoSelector.watchNow') }}
                  </div>
                </div>
                <p class="control d" v-if="isHost" style="display: none">
                  <b-button class="has-background-primary has-text-white-bis"
                    @click="ChangePlayersSlide(video.id, index)">
                    <d-icon icon="FaUsers" />
                  </b-button>
                </p>
              </b-field>
              <div
                class="card-content is-flex is-flex-direction-column is-justify-content-space-between pt-4 pb-0 has-text-left">
                <div class="title is-size-6 mt-0 mb-2 pb-2">{{ video.title }}</div>
                <json-rich-text v-if="video.description" :text="video.description" />
              </div>

            </div>

            <div class="link-buttons columns is-mobile" v-if="video.buttons.length">
              <div v-for="(button, index) in video.buttons" class="column"
                :class="buttonClass(video.buttons.length, index)" :key="button.id">
                <a :href="GetFullUrl(button.link)" class="button is-fullwidth is-primary is-outlined">
                  <d-icon v-if="button.icon" :icon="button.icon" size="is-small" class="mr-1" />
                  {{ button.label }}
                </a>
              </div>
            </div>
          </div>
        </vue-glide-slide>
      </vue-glide>
    </div>

    <div v-if="videos.length" class="bullets is-flex is-justify-content-center is-align-items-center py-3">
      <div v-for="(video, index) in videos" :key="video.id" class="bullet"
        :class="{ active: activeSlide == index, 'has-background-primary': playing.index == index }"
        @click="GoToSlide(index)">
        <svg v-if="playing.index == index" xmlns="http://www.w3.org/2000/svg" width="5" height="5"
          viewBox="0 0 55.127 61.182">
          <path fill="white"
            d="M18.095,1.349C12.579-1.815,8.107.777,8.107,7.134v46.91c0,6.363,4.472,8.952,9.988,5.791l41-23.514c5.518-3.165,5.518-8.293,0-11.457Z" />
        </svg>
      </div>
    </div>

    <b-modal v-model="promptConfirm.show" has-modal-card :can-cancel="false">
      <div class="modal-card m-4 has-text-centered" style="width: auto">
        <header class="card-header has-background-white-bis">
          <p class="card-header-title is-justify-content-center" v-html="$t('exp_videoSelector.youWantToChange')"></p>
        </header>
        <section class="modal-card-body has-background-light pb-3">
          <div class="is-flex is-flex-direction-column is-justify-content-center">
            <div v-html="$t('exp_videoSelector.theseUsersAreWatching')"></div>
            <div class="playerlist mt-3 px-5">
              <player-avatar v-for="player in players" :player="player" :key="player.id" :scale="2" :showUsername="true"
                :horizontal="true" class="mb-2" />
            </div>
          </div>

        </section>
        <footer class="modal-card-foot is-justify-content-end">
          <b-button :label="$t('system.cancel')" type="is-primary is-outlined has-background-white-bis" size="is-small"
            expanded @click="promptConfirm.show = false" />
          <b-button :label="$t('exp_videoSelector.playNow')" type="is-primary" size="is-small" expanded
            @click="PlayVideo(promptConfirm.videoId); promptConfirm.show = false" />
        </footer>
      </div>

    </b-modal>

    <b-modal v-model="showNotAllowVideo" has-modal-card :can-cancel="false">
      <div class="modal-card m-4 has-text-centered" style="width: auto">
        <header class="card-header has-background-white-bis">
          <p class="card-header-title is-justify-content-center" v-html="$t('exp_videoSelector.dontAllowChange')"></p>
        </header>
        <section class="modal-card-body has-background-light pb-3">
          <div class="is-flex is-flex-direction-column is-justify-content-center">
            <div v-html="$t('exp_videoSelector.dontAllowChangeDescription')"></div>
          </div>
        </section>
        <footer class="modal-card-foot is-justify-content-end">
          <b-button :label="$t('system.close')" type="is-primary" size="is-small" expanded
            @click="showNotAllowVideo = false" />
        </footer>
      </div>

    </b-modal>
  </div>
</template>

<script>
import JsonRichText from '../JsonRichText.vue';
import 'vue-glide-js/dist/vue-glide.css';
import { Glide, GlideSlide } from 'vue-glide-js';
import './VideoSelector.scss';
import PlayerAvatar from '../PlayerAvatar.vue';

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
    JsonRichText,
    PlayerAvatar,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      arrowsTop: 0,
      activeSlide: 0,
      videos: [],
      playing: {
        index: null,
        id: null
      },
      allowGuestsToInterruptVideos: false,
      showNotAllowVideo: false,
      promptConfirm: {
        show: false,
        videoId: undefined,
      },
      sliderId: 0, // al cambiarlo fuerzo al slider a rerenderizarse
    };
  },
  computed: {
    players() {
      // Jugadores que no sean yo. Máximo 5
      return this.$store.state.user.players
        .filter(x => x.dbid != this.$store.state.user.profile.id)
        .slice(0, 5);
    },
    isHost() {
      return this.$store.getters["user/hasAdminAuthority"]
    },
    EnableSwipe() {
      const defaultValue = 80;
      if (this.isHost) return defaultValue;
      if (this.$parent.hostControl) return false;
      return defaultValue;
    }
  },
  watch: {
    EnableSwipe(newState, oldState) {
      if (newState != oldState) {
        this.sliderId++
      }
    }
  },
  methods: {
    GetFullUrl(url) {
      if (!url.includes('http')) {
        url = "//" + url
      }
      return url
    },
    SwipeEnded() {
      // Dejo este callback acá por si lo necesitamos
    },
    buttonClass(buttons, index) {
      if (buttons == 3 && index == 0) return "is-full-mobile";
      if (buttons == 4) return "is-half-mobile";
      return false;
    },
    allowChangeVideo() {
      if (this.$store.state.user.players.length == 1) return true; // estoy solo
      if (this.isHost) return true; // el guia puede hacer lo que quiera
      if (this.playing.id == null) return true; // no hay otro video reproduciendo
      if (this.allowGuestsToInterruptVideos == 'allow') return true; // cualquiera puede cambiar el video
      return false;
    },
    ChangeVideo(id) {
      console.log('🔧 INTERRUMPT OPTION', this.allowGuestsToInterruptVideos)

      if (this.playing.id == id) return
      if (!this.isHost && this.$parent.hostControl) return
      if (this.allowChangeVideo()) {
        console.log('Allow')
        this.PlayVideo(id)
      } else {
        if (this.allowGuestsToInterruptVideos == 'promptFirst') {
          console.log('promp first')
          this.promptConfirm.show = true
          this.promptConfirm.videoId = id
        } else {
          this.showNotAllowVideo = true
        }

        this.$sfxPlay('atentionnotification')

      }
    },
    PlayVideo(id) {
      this.$sfxPlay('click')
      console.log("📤 Send gameMessage")
      this.$socket.client.emit('gameMessage', {
        type: "setVideo",
        videoId: id,
        isHost: this.isHost,
      });
    },
    GoToSlide(index) {
      if (this.$parent.hostControl && !this.isHost) return
      this.activeSlide = index;
    },
    SwipeSlide(dir) {
      if (this.$parent.hostControl && this.isHost)
        if (this.$parent.hostControl && !this.isHost) return
      if (dir == 'next') {
        this.activeSlide++
      } else {
        this.activeSlide--
      }

      if (this.$parent.hostControl && this.isHost) console.log('ahora')
    },
    ChangePlayersSlide(id) {
      console.log("📤  ChangePlayersSlide")
      this.$socket.client.emit('roomPlayersMessage', {
        type: "SetSlide",
        slide: id,
      });
    },
  },
  sockets: {
    SetSlide(data) {
      if (this.isHost) return
      console.log("🔒 SetSlide", data)
      this.activeSlide = data.slide
    },
    playingVideo(data) {
      console.log("📺  PlayVideo", data)
      this.videos.forEach((video, index) => {
        if (data.videoId == video.id) {
          this.playing = { index: index, id: video.id }

          if (!this.isHost && this.$parent.hostControl) {
            setTimeout(() => {
              this.activeSlide = index
            }, 500);
          }
          if (data.selectedByHost) {
            this.$sfxPlay('hostselected');
          }
        }
      })
    },
    unsetSlide() {
      this.playing = { index: null, id: null }
    }
  },
  mounted() {
    Object.keys(this.options).forEach((key) => {
      this[key] = this.options[key];
    })
    this.$sfxLoad(this.options.sounds,
      [
        'click',
        'atentionnotification',
        'hostselected'
      ]
    )

    this.allowGuestsToInterruptVideos = this.options.config.allowGuestsToInterruptVideos || 'promptFirst';


  },
};
</script>